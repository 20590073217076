











































































import { Vue, Component } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'

@Component({
  components: {
    HeaderBox
  }
})
export default class BidInfo extends Vue {
  loading: boolean = false
  carInfo = {}
  bidInfo = {}
  tableData = []
  totalNum: number = 0
  
  created () {
    const { center_car_id, auction_id } = this.$route.query;
    this.getBidInfo(center_car_id, auction_id);
  }

  async getBidInfo (centerCarId, auctionId) {
    const { code, data } = await this.$rest.carManage.getBidInfo({
      center_car_id: centerCarId,
      auction_id: auctionId
    });
    if (code !== 0 ) return;
    this.carInfo = data.car_info;
    this.bidInfo = data.bid_info;
    this.tableData = data.history_bid_record;
  }

  carStatus (val) {
    if (val === 2) {
      return '竞拍中'
    } else if (val === 3 || val === 5 || val === 6) {
      return '已拍出'
    } else if (val === 4) {
      return '流拍'
    }
    return ''
  }
}
